import React from 'react';
import { Box, Stack, Typography } from '@mui/joy';
import { MobileNavigationBarProps } from '../../types/interfaces';
import { Colors, FlexAlign, FontSizes } from '../../theme';

import { DashboardTabItem } from '../../types/interfaces';
import { useWindowWidth } from '@react-hook/window-size';
import { MOBILE_NAV_BAR_HEIGHT } from '../../config/constants';

const handleItemClick = ({
  onClick,
  label,
}: {
  onClick: DashboardTabItem['onClick'];
  label: DashboardTabItem['label'];
}) => {
  onClick(label);
};

const Item = ({ label, onClick, Icon, isFocused }: DashboardTabItem & { isFocused: boolean }) => {
  return (
    <Stack
      direction="column"
      onClick={() => onClick(label)}
      sx={{ ...FlexAlign.JustifyStartAlignCenter }}
    >
      <Icon color={isFocused ? Colors.Green : Colors.White50} />

      <Typography
        sx={{
          color: isFocused ? Colors.Green : Colors.White50,
          ...FontSizes.SmallerW600,
          pointerEvents: 'none',
          marginTop: '1px',
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

const MobileNavigationBar = ({
  navItems,
  isBottomNotchPresent,
  currentTabLabel,
}: MobileNavigationBarProps) => {
  const NavBarHeight = isBottomNotchPresent ? '82px' : MOBILE_NAV_BAR_HEIGHT;

  const width = useWindowWidth();

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: Colors.Black,
        height: NavBarHeight,
        justifyContent: 'space-between',
        paddingTop: '6px',
        bottom: 0,
        position: 'fixed',
        width,
        zIndex: 10,
      }}
    >
      {navItems.map((item) => (
        <Box sx={{ paddingRight: '8px', paddingLeft: '8px' }} key={item.label}>
          <Item
            label={item.label}
            onClick={() => handleItemClick({ label: item.label, onClick: item.onClick })}
            Icon={item.Icon}
            key={item.label}
            isFocused={item.label === currentTabLabel}
          />
        </Box>
      ))}
    </Stack>
  );
};

export default MobileNavigationBar;
