import { Typography } from '@mui/joy';
import { FontSizes } from '../../theme';
import { HourlyRateLabelProps } from '../../types/interfaces';

const HourlyRateLabel = ({ hourlyRate }: HourlyRateLabelProps) => {
  return (
    <Typography
      sx={{
        backgroundColor: '#82DA9D',
        height: '19px',
        paddingRight: '4px',
        paddingLeft: '4px',
        ...FontSizes.Header4W700,
        display: 'flex',
        alignItems: 'center',
      }}
    >{`$${hourlyRate}/hr`}</Typography>
  );
};

export default HourlyRateLabel;
