import { Box, CircularProgress, Stack, Typography } from '@mui/joy';
import { Img } from 'react-image';
import {
  GigApplicationStatus,
  GigBaseItem,
  GigPreviewItemContext,
  GigStatus,
  GigDetailsFooterBlockModesEnum,
  ToastMode,
  User,
} from '../../types/interfaces';
import GigPreviewItem from '../../components/GigPreviewItem/index';
import { Colors, FlexAlign, FontSizes } from '../../theme';
import useMobileMode from '../../hooks/useMobileMode';
import GigDetailsRequirements from '../../components/GigDetailsRequirements';
import GigDetailsTravelTips from '../../components/GigDetailsTravelTips';
import GigDetailsFooterBlockUnassignedGig from '../../components/GigDetailsFooterBlockUnassignedGig';
import { BackIcon } from '../../assets/svg/index';
import HorizontalSpacer from '../../components/HorizontalSpacer/index';
import { useEffect, useState } from 'react';
import { getGigById } from '../../api/gigs';
import { useNavigate, useParams } from 'react-router-dom';
import GigDetailsFooterBlockAppliedGig from '../../components/GigDetailsFooterBlockAppliedGig';
import GigDetailsFooterBlockAssignedGig from '../../components/GigDetailsFooterBlockAssignedGig';
import GigDetailsFooterBlockLabel from '../../components/GigDetailsFooterBlockLabel';
import GigPlaceholder from '../../assets/images/gig-placeholder.png';
import triggerToast from '../../utils/triggerToast';
import useAnalyticsPageView from '../../hooks/useAnalyticsPageView';
import errorReporting from '../../utils/errorReporting';
import { GigReport } from '../../types/interfaces';
import useAuth from '../../hooks/useAuth';

const hasUserSubmittedGigReport = ({
  gigReports,
  userId,
}: {
  gigReports: GigReport[];
  userId: User['id'];
}) => {
  return gigReports.map((gigReport) => gigReport.userId).includes(userId);
};

const Footer = ({
  gig,
  setGig,
}: {
  gig: GigBaseItem;
  setGig: React.Dispatch<React.SetStateAction<GigBaseItem | null>>;
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  if (
    gig.status === GigStatus.Unassigned &&
    gig.applicationStatus === GigApplicationStatus.Unapplied
  ) {
    return (
      <Box sx={{ marginBottom: '40px' }}>
        <GigDetailsFooterBlockUnassignedGig
          withdrawPolicyUrl={
            'https://frontlinegig.notion.site/Withdrawal-Policy-13ecf10372d24edbb6d835125fe8cd86'
          }
          frontlineTermsOfUseUrl={
            'https://frontlinegig.notion.site/Frontline-Terms-and-Conditions-1454f23fe6cc47a296537f4efbe05963'
          }
          biometricDataPolicyUrl={''}
          biometricDataConsentFormUrl={''}
          gigStatus={gig.status}
          gigId={gig.id}
          setGig={setGig}
        />
      </Box>
    );
  }

  if (gig.status === GigStatus.Cancelled) {
    return (
      <Box sx={{ marginBottom: '40px' }}>
        <GigDetailsFooterBlockLabel currentMode={GigDetailsFooterBlockModesEnum.Cancelled} />
      </Box>
    );
  }

  if (
    gig.status === GigStatus.Unassigned &&
    gig.applicationStatus === GigApplicationStatus.Pending
  ) {
    return (
      <Box sx={{ marginBottom: '40px' }}>
        <GigDetailsFooterBlockAppliedGig gigId={gig.id} setGig={setGig} />
      </Box>
    );
  }

  if (gig.applicationStatus === GigApplicationStatus.Declined) {
    return (
      <Box sx={{ marginBottom: '40px' }}>
        <GigDetailsFooterBlockLabel currentMode={GigDetailsFooterBlockModesEnum.Declined} />
      </Box>
    );
  }

  if (
    gig.status === GigStatus.Assigned &&
    hasUserSubmittedGigReport({ gigReports: gig.reports as GigReport[], userId: user.id }) ===
      false &&
    gig.applicationStatus === GigApplicationStatus.Accepted
  ) {
    return (
      <Box sx={{ marginBottom: '40px' }}>
        <GigDetailsFooterBlockAssignedGig
          onReportClick={() =>
            navigate(`/user/gig-report/${gig.id}`, {
              state: {
                startDateAndTime: gig.startDateAndTime,
                endDateAndTime: gig.endDateAndTime,
              },
            })
          }
          gigId={gig.id}
          setGig={setGig}
        />
      </Box>
    );
  }

  if (
    (hasUserSubmittedGigReport({ gigReports: gig.reports as GigReport[], userId: user.id }) ||
      gig.status === GigStatus.Completed) &&
    gig.applicationStatus === GigApplicationStatus.Accepted
  ) {
    return (
      <Box sx={{ marginBottom: '40px' }}>
        <GigDetailsFooterBlockLabel currentMode={GigDetailsFooterBlockModesEnum.Completed} />
      </Box>
    );
  }

  return null;
};

const GraySeparator = () => (
  <Box sx={{ width: '100%', height: '8px', backgroundColor: Colors.GrayLight }}></Box>
);

const GigDetails = ({
  gigIdFromProp,
  goBack,
}: {
  gigIdFromProp?: GigBaseItem['id'];
  goBack?: () => void;
}) => {
  useAnalyticsPageView('Gig Details');
  const mobileMode = useMobileMode();
  const { gigId: gigIdFromUrl } = useParams();

  const modalMode = gigIdFromProp && goBack;

  const gigId = modalMode ? gigIdFromProp : gigIdFromUrl;
  const handleBackClick = modalMode ? goBack : () => navigate(-1);

  const [gig, setGig] = useState<GigBaseItem | null>(null);

  const [loadingGig, setLoadingGig] = useState(true);
  const [errorLoadingGig, setErrorLoadingGig] = useState(false);

  const navigate = useNavigate();

  const mobileAwareBorderRadius = mobileMode ? '0px' : '16px';
  const mobileAwareHorizontalMargin = mobileMode ? '0 0 0 0' : '0px 48px 8px 48px';

  useEffect(() => {
    (async () => {
      try {
        const response = await getGigById({ gigId: gigId as string });
        setGig(response.data.gig);
      } catch (error: any) {
        console.error(error);
        errorReporting.captureException(error, {
          level: 'error',
        });
        setErrorLoadingGig(true);
        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while getting this gig. Please retry or contact support.',
        });
      } finally {
        setLoadingGig(false);
      }
    })();
  }, [gigId]);

  if (loadingGig) {
    return (
      <Stack direction="column" sx={{ height: '100vh', ...FlexAlign.Center }}>
        <CircularProgress />
      </Stack>
    );
  }

  if (errorLoadingGig || gig === null) {
    return (
      <Stack direction="column" sx={{ backgroundColor: Colors.GrayLight, height: '100vh' }}>
        <Box
          sx={{ height: '36px', width: '36px', margin: mobileMode ? '32px' : '48px' }}
          onClick={handleBackClick}
        >
          <BackIcon />
        </Box>

        <Box sx={{ ...FlexAlign.Center, flex: 1, padding: '30px' }}>
          <Typography
            sx={{
              color: Colors.Black,
              ...FontSizes.Header3W700,
              flex: 1,
              ...FlexAlign.Center,
              textJustify: 'auto',
            }}
          >
            There has been an error while loading your gig, please retry.
          </Typography>
        </Box>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      sx={{
        position: 'absolute',
        zIndex: 2,
        backgroundColor: Colors.GrayLight,
        width: '100%',
      }}
    >
      {mobileMode ? (
        <Box sx={{ position: 'absolute', top: 25, left: 15 }} onClick={handleBackClick}>
          <BackIcon />
        </Box>
      ) : (
        <Stack direction="row" sx={{ margin: '48px', alignItems: 'center' }}>
          <Box sx={{ height: '36px', width: '36px' }} onClick={handleBackClick}>
            <BackIcon />
          </Box>
          <Typography sx={{ marginLeft: '16px', ...FontSizes.Header1W700 }}>{gig.name}</Typography>
          <HorizontalSpacer />
        </Stack>
      )}

      <Img
        src={gig.featuredImage || GigPlaceholder}
        alt={'featuredImage'}
        loader={
          <Box
            sx={{
              height: mobileMode ? '360px' : '210px',
              ...FlexAlign.Center,
            }}
          >
            <CircularProgress size={'sm'} />
          </Box>
        }
        width={mobileMode ? '100%' : undefined}
        style={{
          objectFit: 'cover',
          height: mobileMode ? '360px' : '210px',
          borderRadius: mobileAwareBorderRadius,
          margin: mobileAwareHorizontalMargin,
        }}
      />

      <GigPreviewItem
        id={gig.id}
        name={gig.name}
        ISO8601DateTimeStart={gig.startDateAndTime}
        ISO8601DateTimeEnd={gig.endDateAndTime}
        location={gig.location}
        hourlyRate={gig.hourlyRate}
        estimatedPrice={gig.estimatedPrice}
        bookmarked={gig.bookmarked}
        status={gig.status}
        city={gig.city}
        componentContext={GigPreviewItemContext.GigDetails}
        applicationStatus={gig.applicationStatus}
        handleClick={() => null}
      />

      <GraySeparator />

      <GigDetailsRequirements requirements={gig.requirements} />

      <GraySeparator />

      <GigDetailsTravelTips travelTips={gig.travelTips} />

      <GraySeparator />

      <Footer gig={gig} setGig={setGig} />
    </Stack>
  );
};

export default GigDetails;
