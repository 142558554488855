import React from 'react';
import { CssVarsProvider } from '@mui/joy';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { deepmerge } from '@mui/utils';
import { experimental_extendTheme as extendMuiTheme } from '@mui/material/styles';

import Home from './pages/Home';
import SignIn from '../src/pages/Signin';
import NotFound from './pages/NotFound';
import Signup from './pages/Signup';
import frontlineTheme from './theme';
import Dashboard from './pages/Dashboard';
import ForgetPassword from './pages/ForgetPassword';
import Verification from './pages/Verification';
import { AuthProvider } from './hooks/useAuth';
import PublicRoute from './components/Common/PublicRoute';
import ProtectedLayout from './components/Common/ProtectedLayout';
import ForgetPasswordVerification from './pages/ForgetPassword/Verification';
import Success from './pages/ForgetPassword/ResetPassword/Success';
import ResetPassword from './pages/ForgetPassword/ResetPassword';
import Onboarding from './pages/Onboarding';
import GigDetails from './pages/GigDetails';
import GigReport from './pages/GigReport';
import AdminPanel from './pages/AdminPanel';
import { Helmet } from 'react-helmet';

const HardReset = () => {
  window.localStorage.clear();
  window.location.href = '/';
  return null;
};

const DefaultAdminFunction = () => {
  window.location.href = '/admin/list-all-gigs';
  return null;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PublicRoute>
        <Helmet>
          <title>{'Frontline | Home'}</title>
        </Helmet>

        <Home />
      </PublicRoute>
    ),
  },

  {
    path: 'login',
    element: (
      <PublicRoute>
        <Helmet>
          <title>{'Frontline | Login'}</title>
        </Helmet>
        <SignIn />
      </PublicRoute>
    ),
  },
  {
    path: 'signup',
    element: (
      <PublicRoute>
        <Helmet>
          <title>{'Frontline | Signup'}</title>
        </Helmet>
        <Signup />
      </PublicRoute>
    ),
  },
  {
    path: 'onboarding',
    element: (
      <ProtectedLayout>
        <Onboarding />
      </ProtectedLayout>
    ),
  },
  {
    path: 'forget-password',
    element: (
      <PublicRoute>
        <Helmet>
          <title>{'Frontline | Forgot Password'}</title>
        </Helmet>
        <ForgetPassword />
      </PublicRoute>
    ),
  },
  {
    path: '/forget-password/verify',
    element: (
      <PublicRoute>
        <Helmet>
          <title>{'Frontline | Verification'}</title>
        </Helmet>
        <ForgetPasswordVerification />
      </PublicRoute>
    ),
  },
  {
    path: '/forget-password/success',
    element: (
      <PublicRoute>
        <Helmet>
          <title>{'Frontline | Reset Password Success'}</title>
        </Helmet>
        <Success />
      </PublicRoute>
    ),
  },
  {
    path: 'reset-password/:token',
    element: (
      <PublicRoute>
        <Helmet>
          <title>{'Frontline | Update Password'}</title>
        </Helmet>
        <ResetPassword />
      </PublicRoute>
    ),
  },
  {
    path: 'verification',
    element: (
      <PublicRoute>
        <Helmet>
          <title>{'Frontline | Verification'}</title>
        </Helmet>
        <Verification />
      </PublicRoute>
    ),
  },
  {
    path: '/dashboard/home/:section',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Home'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Home'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/find-gigs',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Find Gigs'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Find Gigs'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/my-gigs/:section',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | My Gigs'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'My Gigs'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/payment',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Payment'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Payment'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Profile'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile/profile-view',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | View Profile'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile/profile-edit',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Edit Profile'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile/my-skills',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | My Skills'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile/my-gig-wishlist',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Gig Wishlist'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile/onboarding',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Onboarding'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile/gig-academy',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Gig Academy'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile/set-password',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Set Password'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile/logout',
    element: (
      <ProtectedLayout>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile/help',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Help'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/dashboard/profile/legal',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Legal'}</title>
        </Helmet>
        <Dashboard currentTabLabel={'Profile'} />
      </ProtectedLayout>
    ),
  },
  {
    path: '/gigs/:gigId',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Gig Detail'}</title>
        </Helmet>
        <GigDetails />
      </ProtectedLayout>
    ),
  },
  {
    path: '/user/gig-report/:gigId',
    element: (
      <ProtectedLayout>
        <Helmet>
          <title>{'Frontline | Gig Report'}</title>
        </Helmet>
        <GigReport />
      </ProtectedLayout>
    ),
  },
  {
    path: '/utils/hardreset',
    element: <HardReset />,
  },
  {
    path: '/admin',
    element: <DefaultAdminFunction />,
  },
  {
    path: '/admin/:action',
    element: (
      <ProtectedLayout>
        <AdminPanel />
      </ProtectedLayout>
    ),
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

const muiTheme = extendMuiTheme();

// You can use your own `deepmerge` function.
// joyTheme will deeply merge to muiTheme.
export const theme = deepmerge(muiTheme, frontlineTheme);

const App = () => {
  return (
    <CssVarsProvider theme={theme}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </CssVarsProvider>
  );
};

export default App;
