import axios from '.';
import { GIGS_API_PREFIX, GIGS_SEARCH_API_PREFIX } from '../config/constants';
import { GigApplicationStatus, User } from '../types/interfaces';
import {
  GigBaseItem,
  GigReportFormData,
  GigStatus,
  IPaginator,
  CreateGigFormData,
  AdminGigBaseItem,
  EditGigFormData,
} from '../types/interfaces';

/**
 * This method hits `/gigs` API.
 * @returns Promise with gigs array
 */
export const getAllGigs = async ({ page }: { page: number }) => {
  return axios.get<{ gigs: GigBaseItem[]; paginator: IPaginator }>(
    `/${GIGS_API_PREFIX}?page=${page}`,
  );
};

/**
 * This method hits `/gigs/:gidId` API.
 * @returns Promise with gig detail
 */
export const getGigById = async ({ gigId }: { gigId: GigBaseItem['id'] }) => {
  return axios.get<{ gig: GigBaseItem }>(`/${GIGS_API_PREFIX}/${gigId}`);
};

export const addGigReport = async ({ formData }: { formData: GigReportFormData }) => {
  return axios.post(`user/gig-report`, formData);
};

export const createGig = async ({ formData }: { formData: CreateGigFormData }) => {
  return axios.post(`/${GIGS_API_PREFIX}`, formData);
};

export const editGig = async ({
  formData,
  gigId,
}: {
  formData: EditGigFormData;
  gigId: AdminGigBaseItem['id'];
}) => {
  return axios.patch(`/${GIGS_API_PREFIX}/${gigId}`, formData);
};

export const getAllGigsBySkills = async ({ page, skills }: { page: number; skills: string[] }) => {
  return axios.post(`/${GIGS_SEARCH_API_PREFIX}?page=${page}`, { skills });
};

export const gigHistoryByStatus = async ({ status, page }: { status: GigStatus; page: number }) => {
  return axios.get(`/${GIGS_API_PREFIX}/gig-history`, {
    params: {
      status: status.toLowerCase(),
      page,
    },
  });
};

export const applyToGig = async ({
  gigId,
  acceptApplicationTerms,
}: {
  gigId: GigBaseItem['id'];
  acceptApplicationTerms: boolean;
}) => {
  return axios.post(`/${GIGS_API_PREFIX}/apply-to-gig`, {
    gigId,
    acceptApplicationTerms,
  });
};

export const adminGetAllGigs = async ({
  status,
  page,
  ownedOnly,
}: {
  status: GigStatus;
  page: number;
  ownedOnly: boolean;
}) => {
  return axios.get<{ gigs: AdminGigBaseItem[]; paginator: IPaginator }>(
    `/${GIGS_API_PREFIX}/admin-all-gigs?status=${status}&page=${page}&ownedOnly=${ownedOnly}`,
  );
};

export const adminEditApplication = async ({
  gigId,
  status,
  workerId,
}: {
  gigId: GigBaseItem['id'];
  status: GigApplicationStatus;
  workerId: User['id'];
}) => {
  return axios.patch(`/${GIGS_API_PREFIX}/gig-application/${gigId}`, {
    status,
    workerId,
  });
};

export const deleteGigApplication = async ({ gigId }: { gigId: GigBaseItem['id'] }) => {
  return axios.delete(`/${GIGS_API_PREFIX}/gig-application/${gigId}`);
};
