import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import errorReporting from './utils/errorReporting';
import analytics from './utils/analytics';

errorReporting.init();

analytics.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
  debug: process.env.REACT_APP_ENVIRONMENT === 'development',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
    </Helmet>
    <Toaster position="top-center" reverseOrder={false} />
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
