import { Box, CircularProgress, Typography } from '@mui/joy';
import { uniqBy } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllGigs } from '../../../../../api/gigs';
import GigPreviewItem from '../../../../../components/GigPreviewItem';
import LoadMoreButton from '../../../../../components/LoadMoreButton';
import ThinGrayDarkLine from '../../../../../components/ThinGrayDarkLine';
import UnlockMoreGigsEntry from '../../../../../components/UnlockMoreGigsEntry';
import useMobileMode from '../../../../../hooks/useMobileMode';
import { Colors, FlexAlign, FontSizes } from '../../../../../theme';
import {
  GigBaseItem,
  GigPreviewItemContext,
  IPaginator,
  ToastMode,
} from '../../../../../types/interfaces';
import GigPlaceholder from '../../../../../assets/images/gig-placeholder.png';
import triggerToast from '../../../../../utils/triggerToast';
import useAnalyticsPageView from '../../../../../hooks/useAnalyticsPageView';
import errorReporting from '../../../../../utils/errorReporting';

const FeedSection = ({
  onUnlockMoreGigsClick,
  isPaymentMethodAdded,
  isBasicGigTrainingCompleted,
}: {
  onUnlockMoreGigsClick: () => void;
  isPaymentMethodAdded: boolean;
  isBasicGigTrainingCompleted: boolean;
}) => {
  useAnalyticsPageView('Home Tab - Feed');

  const mobileMode = useMobileMode();
  const [gigs, setGigs] = useState<GigBaseItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [paginator, setPaginator] = useState<IPaginator>();
  const [currentPage, setCurrentPage] = useState(1);
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        gigs?.length ? setBtnLoading(true) : setLoading(true);
        const res = await getAllGigs({ page: currentPage });

        if (Array.isArray(res?.data?.gigs)) {
          setGigs(uniqBy([...(gigs as GigBaseItem[]), ...res?.data?.gigs], 'id'));
          setPaginator(res.data?.paginator);
        }
      } catch (error: any) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });

        triggerToast({
          mode: ToastMode.Error,
          error,
          fallbackErrorMessage:
            'An error occurred while getting gigs. Please retry or contact support.',
        });
      } finally {
        setLoading(false);
        setBtnLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const horizontalPadding = mobileMode
    ? { paddingLeft: '16px', paddingRight: '16px' }
    : { paddingLeft: '88px', paddingRight: '88px' };

  return (
    <Box sx={{ ...horizontalPadding }}>
      <Typography
        sx={{
          ...FontSizes.Header1W700,
          color: Colors.Black,
          marginTop: '48px',
        }}
      >
        Gigs feed
      </Typography>

      {loading ? (
        <Box sx={{ marginTop: '20px', width: '100%', ...FlexAlign.Center }}>
          <CircularProgress size={'sm'} />
        </Box>
      ) : null}

      {gigs.map((gig, index) => (
        <Box key={gig.id}>
          <GigPreviewItem
            key={gig.id}
            id={gig.id}
            name={gig.name}
            imageUrl={gig.featuredImage || GigPlaceholder}
            location={gig.location}
            hourlyRate={gig.hourlyRate}
            status={gig.status}
            clientRating={undefined}
            estimatedPrice={gig.estimatedPrice}
            ISO8601DateTimeStart={gig.startDateAndTime}
            ISO8601DateTimeEnd={gig.endDateAndTime}
            bookmarked={gig.bookmarked}
            city={gig.city}
            applicationStatus={gig.applicationStatus}
            componentContext={GigPreviewItemContext.Feed}
            handleClick={() => navigate(`/gigs/${gig.id}`)}
          />
          {index !== gigs.length - 1 && <ThinGrayDarkLine />}
        </Box>
      ))}

      <LoadMoreButton next={paginator?.next} onClick={handleLoadMore} loading={btnLoading} />

      <Box sx={{ marginTop: 4, marginBottom: '50px' }}>
        {isPaymentMethodAdded === false || isBasicGigTrainingCompleted === false ? (
          <UnlockMoreGigsEntry onClick={onUnlockMoreGigsClick} />
        ) : null}
      </Box>
    </Box>
  );
};

export default memo(FeedSection);
