import { Box, CircularProgress, Modal, Stack, Typography } from '@mui/joy';
import {
  GigBaseItem,
  Skill,
  IPaginator,
  GigPreviewItemContext,
  ToastMode,
} from '../../../../types/interfaces';
import { getSkills } from '../../../../api/skill';
import { Colors, FlexAlign, FontSizes } from '../../../../theme';
import GigPreviewItem from '../../../../components/GigPreviewItem/index';
import ThinGrayDarkLine from '../../../../components/ThinGrayDarkLine';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import useMobileMode from '../../../../hooks/useMobileMode';
import { Button } from '@mui/material';
import Autocomplete from '@mui/joy/Autocomplete';
import { getAllGigsBySkills } from '../../../../api/gigs';
import { SearchIcon } from '../../../../assets/svg';
import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import LoadMoreButton from '../../../../components/LoadMoreButton';
import { WEB_NAVIGATION_BAR_WIDTH } from '../../../../config/constants';
import GigPlaceholder from '../../../../assets/images/gig-placeholder.png';
import GigDetails from '../../../GigDetails';
import triggerToast from '../../../../utils/triggerToast';
import useAnalyticsPageView from '../../../../hooks/useAnalyticsPageView';
import analytics from '../../../../utils/analytics';
import errorReporting from '../../../../utils/errorReporting';

const FindGigs = () => {
  useAnalyticsPageView('FindGigs Tab');
  const [gigs, setGigs] = useState<GigBaseItem[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [paginator, setPaginator] = useState<IPaginator>();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const resetAll = () => {
    setPaginator(undefined);
    setCurrentPage(1);
    setGigs([]);
    setSelectedSkills([]);
  };

  /**
   * @description When the selected skills change, the paginator
   * nad the current page would be outdated as it would refer
   * to a db result that now has different filters
   */
  const paginatorAwareSetSelectedSkills = (value: Skill[]) => {
    resetAll();
    setSelectedSkills(value);
  };

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getSkills();
        setSkills(res?.data?.skills);
      } catch (error) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedSkills.length === 0) return;

    (async () => {
      try {
        gigs?.length ? setBtnLoading(true) : setLoading(true);
        const res = await getAllGigsBySkills({
          page: currentPage,
          skills: selectedSkills.map((skill) => skill.id),
        });

        if (Array.isArray(res?.data?.gigs)) {
          setGigs(uniqBy([...gigs, ...res?.data?.gigs], 'id'));
          setPaginator(res?.data?.paginator);
        }
      } catch (error) {
        console.log('error', error);

        errorReporting.captureException(error, {
          level: 'error',
        });
      } finally {
        setLoading(false);
        setBtnLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  /**
   * @description This method filters gigs by skills
   */
  const onSearch = async () => {
    try {
      setLoading(true);
      const res = await getAllGigsBySkills({
        page: currentPage,
        skills: selectedSkills.map((skill) => skill.id),
      });

      if (Array.isArray(res?.data?.gigs)) {
        setGigs(res.data.gigs);
        setPaginator(res?.data?.paginator);
      }
      analytics.track('Search For Gigs');
    } catch (error: any) {
      console.log('error', error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while searching gigs. Please retry or contact support.',
      });
    } finally {
      setLoading(false);
    }
  };

  const height = useWindowHeight();
  const width = useWindowWidth();
  const mobileMode = useMobileMode();

  const [modalGigId, setModalGigId] = useState<string | null>(null);
  const closeGigDetailsModal = () => setModalGigId(null);

  const MobileWidth = width - 32;
  const WebWidth = width - 32 - WEB_NAVIGATION_BAR_WIDTH;

  const ResponsiveWidth = mobileMode ? MobileWidth : WebWidth;

  return (
    <Stack
      direction="column"
      sx={{
        ...FlexAlign.JustifyStartAlignCenter,
        width: '100%',
        height: mobileMode ? height - 55 : height,
        overflowY: 'scroll',
      }}
    >
      {modalGigId && (
        <Modal open>
          <GigDetails gigIdFromProp={modalGigId} goBack={closeGigDetailsModal} />
        </Modal>
      )}

      <Stack direction="row" mt={4} gap={2} sx={{ position: 'relative', width: ResponsiveWidth }}>
        <Autocomplete
          startDecorator={<SearchIcon />}
          multiple
          freeSolo
          limitTags={6}
          placeholder="Skills"
          options={skills}
          onChange={(_, value, reason) => {
            if (reason === 'clear') {
              resetAll();
              return;
            }

            if (reason === 'removeOption' && selectedSkills.length > 1) {
              const previousSelectedSkills = [...selectedSkills];
              resetAll();
              paginatorAwareSetSelectedSkills(previousSelectedSkills);
              onSearch();
            }

            paginatorAwareSetSelectedSkills(value as Skill[]);
          }}
          getOptionLabel={(option: any) => option.name}
          value={selectedSkills}
          sx={{
            flex: 1,
            paddingBlock: '12px',
            borderColor: '#82DA9D',
            borderRadius: '6px',
            paddingRight: '90px',
          }}
        />

        <Button
          variant="contained"
          onClick={onSearch}
          disabled={selectedSkills.length === 0}
          style={{
            minHeight: '32px',
            height: '32px',
            marginRight: '8px',
            position: 'absolute',
            right: 1,
            top: '50%',
            transform: 'translateY(-50%)',
            textTransform: 'none',
          }}
          sx={{
            color: Colors.White,
            borderRadius: '4px',
            backgroundColor: Colors.Blue,
            marginLeft: '8px',
          }}
        >
          Find
        </Button>
      </Stack>

      <Box
        sx={{
          marginTop: '16px',
          width: ResponsiveWidth,
          display: 'flex',
          justifyContent: loading ? 'center' : 'flex-start',
        }}
      >
        {loading ? (
          <CircularProgress size={'sm'} />
        ) : (
          <Typography
            sx={{
              ...FontSizes.Header3W700,
              color: Colors.Black,
              marginTop: '16px',
              marginLeft: '4px',
            }}
          >
            {`${gigs.length} search results`}
          </Typography>
        )}
      </Box>

      {gigs.map((gig, index) => (
        <Box key={index} sx={{ width: ResponsiveWidth }}>
          <GigPreviewItem
            key={gig.id}
            id={gig.id}
            name={gig.name}
            imageUrl={gig.featuredImage || GigPlaceholder}
            location={gig.location}
            hourlyRate={gig.hourlyRate}
            clientRating={undefined}
            status={gig.status}
            estimatedPrice={gig.estimatedPrice}
            ISO8601DateTimeStart={gig.startDateAndTime}
            ISO8601DateTimeEnd={gig.endDateAndTime}
            city={gig.city}
            bookmarked={gig.bookmarked}
            applicationStatus={gig.applicationStatus}
            componentContext={GigPreviewItemContext.FindGigs}
            handleClick={() => {
              setModalGigId(gig.id);
            }}
          />
          {index !== gigs.length - 1 && <ThinGrayDarkLine />}
        </Box>
      ))}

      <LoadMoreButton onClick={handleLoadMore} loading={btnLoading} next={paginator?.next} />
    </Stack>
  );
};

export default FindGigs;
