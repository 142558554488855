import React from 'react';
import { Button, CircularProgress, IconButton, Link, Stack, Typography } from '@mui/joy';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Connector from '../../components/Connector';
import AuthLayout from '../../layout/authLayout';
import { SignUpValidationSchema } from '../../validation';
import StepFive from './steps/StepFive';
import StepFour from './steps/StepFour';
import StepOne from './steps/StepOne';
import StepSix from './steps/StepSix';
import StepThree from './steps/StepThree';
import StepTwo from './steps/StepTwo';
import { BackIcon } from '../../assets/svg';
import { isAxiosError } from 'axios';
import { register, sendSmsVerificationCode } from '../../api/auth';
import { setAuthToken } from '../../api';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TIME_INTERVAL_FOR_MESSAGE } from '../../config/constants';
import { backBtnStyles } from '../../config/styleConstants';
import CustomAlert from '../../components/CustomAlert';
import useShowAlert from '../../hooks/useShowAlert';
import triggerToast from '../../utils/triggerToast';
import { ToastMode } from '../../types/interfaces';
import useAnalyticsPageView from '../../hooks/useAnalyticsPageView';
import analytics from '../../utils/analytics';
import errorReporting from '../../utils/errorReporting';

export const steps = [
  'Start',
  'Please update to What is your full name?',
  'Hi Kale, what’s you phone number?',
  'Enter your code',
  'Kale, let’s set up your login details',
  'Where are you based? ',
];

export const Step = ({
  stepCount,
  setStepCount,
}: {
  stepCount: number;
  setStepCount: Function;
}) => {
  const Steps = [
    <StepOne />,
    <StepTwo />,
    <StepFive />,
    <StepSix />,
    <StepThree />,
    <StepFour setStepCount={setStepCount} />,
  ];
  return <>{Steps[stepCount]}</>;
};

const defaultValues = {
  termsAndConditions: false,
  firstName: '',
  middleName: '',
  lastName: '',
  phoneNumber: undefined,
  email: '',
  password: '',
  zipCode: undefined,
  code: '',
};

const Signup = () => {
  useAnalyticsPageView('Signup');
  const [stepCount, setStepCount] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState('');
  const { showAlert, setShowAlert } = useShowAlert(TIME_INTERVAL_FOR_MESSAGE);
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const methods = useForm({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(SignUpValidationSchema[stepCount]),
    mode: 'onChange',
  });

  const { trigger, getValues, handleSubmit, setError, formState, watch } = methods;
  const onSubmit = async (data: any) => {
    try {
      const res = await register({ ...data, phoneNumber: data.phoneNumber });
      window.localStorage.setItem('token', res?.data?.token);
      setAuthToken(res?.data?.token);
      if (res?.data?.token) {
        setUser({
          token: res?.data?.token || '',
        });
      }
      analytics.track('Signup');
      navigate('/dashboard/home/feed');
    } catch (error) {
      errorReporting.captureException(error, {
        level: 'error',
      });
      handleError(error);
    }
  };

  const handleError = (error: unknown) => {
    if (isAxiosError(error)) {
      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while signing up. Please retry or contact support.',
      });

      if (error.response?.data?.message) {
        if (error?.response?.data?.message.includes('User already exists with phone number')) {
          setError('phoneNumber', { type: 'custom', message: error?.response?.data?.message });
          setStepCount(4);
        } else if (error?.response?.data?.message.includes('User already exists with email')) {
          setError('email', { type: 'custom', message: error?.response?.data?.message });
          setStepCount(2);
        } else {
          setErrorMsg(error.response?.data?.message);
          setShowAlert(true);
        }
      }
    }
  };

  const handleNext = async (e: any) => {
    try {
      e.preventDefault();
      if (stepCount === 4) {
        await sendSmsVerificationCode({
          value: getValues('phoneNumber') || '',
          verifyAccount: false,
        });
      }
      const isStepValid = await trigger();
      if (isStepValid) {
        setStepCount((prevStepCount) => prevStepCount + 1);
      }
    } catch (error) {
      errorReporting.captureException(error, {
        level: 'error',
      });
      if (isAxiosError(error)) {
        setError('phoneNumber', { type: 'custom', message: error.response?.data?.message });
      }
    }
  };

  const handleBack = () => {
    setStepCount((prevStepCount) => prevStepCount - 1);
  };

  return (
    <AuthLayout>
      {stepCount !== 0 && (
        <>
          <IconButton sx={backBtnStyles} onClick={handleBack}>
            <BackIcon />
          </IconButton>
          <Stack gap="6px">
            <Typography fontSize={12} lineHeight="14.63px" fontWeight="bold">
              Step {stepCount} of {steps.length - 1}
            </Typography>
            <Connector steps={steps} activeStep={stepCount} />
          </Stack>
        </>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Step stepCount={stepCount} setStepCount={setStepCount} />
          {showAlert && (
            <CustomAlert
              title="Oops!"
              message={errorMsg!}
              startDecorator={<WarningIcon sx={{ mt: '2px', mx: '4px' }} fontSize="small" />}
              variant="soft"
              color="danger"
              endDecorator={
                <IconButton
                  onClick={() => {
                    setShowAlert(false);
                  }}
                  variant="soft"
                  size="sm"
                  color="danger"
                >
                  <CloseRoundedIcon />
                </IconButton>
              }
            />
          )}
          {stepCount !== steps.length - 1 ? (
            <Button
              disabled={!watch('termsAndConditions')}
              sx={(theme) => ({
                border: `2px solid ${theme.vars.palette.primary[500]}`,
                marginTop: 3,
                width: '100%',
              })}
              variant="outlined"
              onClick={handleNext}
            >
              {stepCount === 0 ? 'Accept' : 'Continue'}
            </Button>
          ) : (
            <Button
              type="submit"
              sx={(theme) => ({
                border: `2px solid ${theme.vars.palette.primary[500]}`,
                marginTop: 3,
                width: '100%',
              })}
              disabled={!formState.isDirty && !formState?.isValid}
              variant="outlined"
            >
              {formState?.isSubmitting ? (
                <CircularProgress variant="solid" color="primary" size="sm" />
              ) : (
                'Submit'
              )}
            </Button>
          )}
          {stepCount === 0 && (
            <Typography mt={3} component="p" fontSize="1rem" textAlign="center">
              Already have an account? <Link onClick={() => navigate('/login')}>Sign In</Link>
            </Typography>
          )}
        </form>
      </FormProvider>
    </AuthLayout>
  );
};

export default Signup;
