import { Box, Stack } from '@mui/joy';
import { DashboardTabItem, WebNavigationBarProps } from '../../types/interfaces';
import { Colors, FlexAlign } from '../../theme';
import { LogoIcon } from '../../assets/svg/index';
import WebNavigatorItem from './WebNavigatorItem';
import { WEB_NAVIGATION_BAR_WIDTH } from '../../config/constants';

const handleItemClick = ({
  onClick,
  label,
}: {
  onClick: DashboardTabItem['onClick'];
  label: DashboardTabItem['label'];
}) => {
  onClick(label);
};

const WebNavigationBar = ({ navItems, currentTabLabel }: WebNavigationBarProps) => {
  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: Colors.Black,
        width: WEB_NAVIGATION_BAR_WIDTH,
        height: '100vh',
      }}
    >
      <Box
        sx={{
          width: '180px',
          height: '180px',
          ...FlexAlign.Center,
          backgroundColor: Colors.Blue,
          marginBottom: '24px',
        }}
      >
        <LogoIcon />
      </Box>

      <Stack direction="column">
        {navItems.map((item) => (
          <WebNavigatorItem
            label={item.label}
            onClick={() => handleItemClick({ label: item.label, onClick: item.onClick })}
            Icon={item.Icon}
            key={item.label}
            isFocused={item.label === currentTabLabel}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default WebNavigationBar;
