import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, FormControl, Stack, styled, Textarea, Typography } from '@mui/joy';
import { Button, FormHelperText, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import InputField from '../../../components/InputField';
import { Colors, FontSizes } from '../../../theme';
import {
  ActionableSearchInputSubmitMode,
  CreateGigFormData,
  Skill,
  ToastMode,
} from '../../../types/interfaces';
import { CreateGigSchema } from '../../../validation';
import { useWindowWidth } from '@react-hook/window-size';
import FeaturedImage from './FeaturedImage';
import GigTime from './GigTime';
import { createGig } from '../../../api/gigs';
import ActionableSearchInput from '../../../components/ActionableSearchInput';
import DeletableSkillTag from '../../../components/DeletableSkillTag';
import { getSkills } from '../../../api/skill';
import { FlexAlign } from '../../../theme/index';
import triggerToast from '../../../utils/triggerToast';
import useAnalyticsPageView from '../../../hooks/useAnalyticsPageView';
import analytics from '../../../utils/analytics';
import CityStateDropdown from '../../../components/CityStateDropdown';
import errorReporting from '../../../utils/errorReporting';

const EditTextareaStyled = styled(Textarea)({
  ...FontSizes.NormalCaptionW300,
  color: Colors.AlmostBlack,
  marginBottom: '24px',
  marginTop: '24px',
  backgroundColor: 'transparent',
  border: 'none',
  paddingInline: 'inherit',
  '& .Joy-disabled': {
    color: 'gray',
  },

  '&:before': {
    all: 'unset',
  },
});

const CreateGig = () => {
  useAnalyticsPageView('Admin - Create Gig');
  const [error, setError] = useState<string>('');
  const resetError = () => setError('');
  const width = useWindowWidth();

  const [gigSuccessfullyCreated, setGigSuccessfullyCreated] = useState<boolean | null>(null);

  const [skills, setSkills] = useState<Skill[]>([]);
  const [skillsLabels, setSkillsLabels] = useState<Skill['name'][]>([]);
  const [selectedSkillsLabels, setSelectedSkillsLabels] = useState<Skill['name'][]>([]);

  useEffect(() => {
    (async () => {
      const res = await getSkills();
      setSkills(res?.data?.skills);
      setSkillsLabels(res?.data?.skills?.map((skill: Skill) => skill.name));
    })();
  }, []);

  const methods = useForm<CreateGigFormData>({
    defaultValues: {
      name: '',
      location: '',
      travelTips: '',
      requirements: '',
      city: '',
      featuredImage: null,
      skills: [],
      startDateAndTime: '',
      endDateAndTime: '',
      hourlyRate: undefined,
      estimatedPrice: undefined,
      description: '',
    },
    resolver: yupResolver(CreateGigSchema),
  });

  const addSelectedSkill = useCallback(
    ({ skillLabel }: { skillLabel: Skill['name'] }) => {
      const updatedSkills = [...selectedSkillsLabels, skillLabel];

      setSelectedSkillsLabels(updatedSkills);
      methods.setValue('skills', updatedSkills);
    },
    [selectedSkillsLabels, methods],
  );

  const removeSelectedSkill = useCallback(
    (skillLabel: string) => {
      const updatedSkills = selectedSkillsLabels.filter((item) => item !== skillLabel);

      setSelectedSkillsLabels(updatedSkills);
      methods.setValue('skills', updatedSkills);
    },
    [selectedSkillsLabels, methods],
  );

  const resetPage = () => {
    setGigSuccessfullyCreated(null);
    setSelectedSkillsLabels([]);
    resetError();
    methods.reset();
  };

  const onSubmit = async (values: any) => {
    resetError();

    try {
      await methods.trigger();

      const formData = new FormData() as CreateGigFormData;
      formData.append('name', values.name);
      formData.append('location', values.location);
      formData.append('requirements', values.requirements);
      formData.append('city', values.city);
      if (values.featuredImage) {
        formData.append('featuredImage', values.featuredImage, values.featuredImage.name);
      }
      formData.append('startDateAndTime', values.startDateAndTime);
      formData.append('endDateAndTime', values.endDateAndTime);
      formData.append('hourlyRate', values.hourlyRate);
      formData.append('estimatedPrice', values.estimatedPrice);
      formData.append('description', values.description);
      formData.append('travelTips', values.travelTips);

      skills
        .filter((skill) => selectedSkillsLabels.includes(skill.name))
        .forEach((skill, index) => {
          formData.append(`skills[${index}]`, skill.id);
        });

      await createGig({ formData });

      setGigSuccessfullyCreated(true);

      analytics.track('Admin - Gig Created');

      // do the request here
    } catch (error: any) {
      console.error(error);

      errorReporting.captureException(error, {
        level: 'error',
      });

      setGigSuccessfullyCreated(false);
      triggerToast({
        mode: ToastMode.Error,
        error,
        fallbackErrorMessage:
          'An error occurred while creating your Gig. Please retry or contact support.',
      });
    }
  };

  const onError = (e: any) => {
    console.log('error');
    console.log(e);
  };

  if (gigSuccessfullyCreated === true) {
    return (
      <Stack direction="column" sx={{ ...FlexAlign.Center }}>
        <Typography>Congratulations! You have successfully created a new Gig</Typography>
        <Button onClick={resetPage}>Create a new Gig</Button>
      </Stack>
    );
  }

  if (gigSuccessfullyCreated === false) {
    return (
      <Stack direction="column" sx={{ ...FlexAlign.Center, maxWidth: width * 0.3 }}>
        <Button onClick={resetPage}>Retry</Button>
      </Stack>
    );
  }

  return (
    <Stack
      sx={(theme) => ({
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'scroll',
        marginRight: '30px',
        marginLeft: '30px',
      })}
      direction="column"
    >
      <FormProvider {...methods}>
        <form>
          <Stack
            direction="column"
            sx={{
              padding: '48px',
              width: width * 0.45,
              height: '100vh',
            }}
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '20px', marginTop: '80px', marginBottom: '40px' }}
            >
              Create new gig
            </Typography>

            <FeaturedImage />

            <InputField label="Enter Gig's Name" name="name" />

            <InputField label="Enter Gig's Location" name="location" />

            <CityStateDropdown
              onCityStateSelected={({ city, state }) =>
                methods.setValue('city', `${city} - ${state}`)
              }
            />

            <InputField label="Enter Gig's Hourly Rate - Enter a number" name="hourlyRate" />
            <InputField
              label="Enter Gig's Estimated Price - Enter a number"
              name="estimatedPrice"
            />

            <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
              <FormControl size="sm" color="primary">
                <EditTextareaStyled
                  minRows={2}
                  maxRows={4}
                  {...methods.register('description')}
                  placeholder={"Enter Gig's description"}
                  autoComplete="off"
                  defaultValue={''}
                  variant="plain"
                  sx={{ fontWeight: 400, color: Colors.Black, fontSize: '18px' }}
                />

                {methods.formState?.errors?.description?.message && (
                  <FormHelperText error>
                    {methods.formState?.errors?.description?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
              <FormControl size="sm" color="primary">
                <EditTextareaStyled
                  minRows={2}
                  maxRows={4}
                  {...methods.register('travelTips')}
                  placeholder={"Enter Gig's Travel Tips"}
                  autoComplete="off"
                  defaultValue={''}
                  variant="plain"
                  sx={{ fontWeight: 400, color: Colors.Black, fontSize: '18px' }}
                />

                {methods.formState?.errors?.travelTips?.message && (
                  <FormHelperText error>
                    {methods.formState?.errors?.travelTips?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box sx={{ borderBottom: `1px solid ${Colors.GrayDark}` }}>
              <FormControl size="sm" color="primary">
                <EditTextareaStyled
                  minRows={2}
                  maxRows={4}
                  {...methods.register('requirements')}
                  placeholder={"Enter Gig's Requirements"}
                  autoComplete="off"
                  defaultValue={''}
                  variant="plain"
                  sx={{ fontWeight: 400, color: Colors.Black, fontSize: '18px' }}
                />

                {methods.formState?.errors?.requirements?.message && (
                  <FormHelperText error>
                    {methods.formState?.errors?.requirements?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <GigTime />

            <Box sx={{ minHeight: '240px' }}>
              <ActionableSearchInput
                onClick={(skillLabel: string) => {
                  methods.clearErrors('skills');
                  addSelectedSkill({ skillLabel });
                }}
                buttonLabel="Add"
                submitMode={ActionableSearchInputSubmitMode.AutoCleanOnSubmit}
                placeholder="Add a skill"
                autoCompleteOptions={skillsLabels}
                autoCompleteSelectedOptions={selectedSkillsLabels}
                validateOnSubmit={(text) => {
                  if (!skillsLabels.includes(text)) {
                    return 'The skill must be chosen from the skills list';
                  }

                  return null;
                }}
              />

              {methods.formState?.errors?.skills?.message && (
                <FormHelperText error>{methods.formState?.errors?.skills?.message}</FormHelperText>
              )}

              <Grid container sx={{ marginTop: 3 }} direction="row">
                {selectedSkillsLabels.map((skillLabel: Skill['name']) => (
                  <Grid item key={skillLabel} sx={{ marginRight: '8px', marginBottom: '12px' }}>
                    <DeletableSkillTag onDeleteClick={removeSelectedSkill}>
                      {skillLabel}
                    </DeletableSkillTag>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {error && (
              <Typography sx={{ ...FontSizes.SmallW300, color: Colors.Red, marginTop: '16px' }}>
                {
                  'An error occurred while creating your Gig. Please try again later or contact support.'
                }
              </Typography>
            )}

            <Button
              sx={(theme) => ({
                border: `2px solid ${Colors.Blue}`,
                marginBottom: '72px',
                marginTop: '24px',
                width: '100%',
              })}
              type="button"
              variant="outlined"
              onClick={methods.handleSubmit(onSubmit, onError)}
              disabled={methods?.formState?.isSubmitting}
            >
              {methods?.formState?.isSubmitting ? (
                <CircularProgress variant="solid" color="primary" size="sm" />
              ) : (
                'Create Gig'
              )}
            </Button>

            <Typography sx={{ marginBottom: '80px' }}>.</Typography>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};

export default CreateGig;
