export const DEFAULT_TIMEOUT_SECONDS = 20;
export const AUTH_API_PREFIX = 'auth';
export const USER_API_PREFIX = 'user';
export const GIGS_API_PREFIX = 'gigs';
export const SKILLS_API_PREFIX = 'skills';
export const PAYMENT_METHODS_API_PREFIX = 'payments';
export const TIME_INTERVAL_FOR_SECONDS = 1000;
export const PRIVACY_AND_DATA_POLICY_DOCUMENT_LINK = 'https://hubs.ly/Q01wqlKW0';
export const TERMS_AND_CONDITIONS_DOCUMENT_LINK = 'https://hubs.ly/Q01wp6zd0';
export const TIME_INTERVAL_FOR_MESSAGE = 5000;
export const PROFILE_PHOTO_FILE_SIZE = 20485760;
export const GIG_IMAGE_FILE_SIZE = 20485760;
export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const GIGS_SEARCH_API_PREFIX = 'gigs/gig-search';
export const TRANSACTIONS_API_PREFIX = 'transactions';
export const HEADER_WITH_TABS_HEIGHT = 180;
export const HEADER_HEIGHT = 100;
export const ONBOARDING_TRAINING_VIDEO_URL = 'https://youtube.com/embed/19liDQBovNE';
export const FRONTLINE_HELP_LINK = 'https://frontlinegig.zendesk.com/hc/en-us';
export const WEB_NAVIGATION_BAR_WIDTH = 180;
export const MOBILE_NAV_BAR_HEIGHT = 49;
export const MOBILE_PROFILE_HEADER_HEIGHT = 108;
