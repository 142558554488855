import axios from '.';
import { AUTH_API_PREFIX } from '../config/constants';
import { IRegisterPayload, ResetPasswordRequestPayload } from '../types/interfaces';

/**
 * This method hits `send-sms-verification-code` API.
 * @param object {Object} that contains phonenumber value
 * @returns Promise
 */
export const sendSmsVerificationCode = async ({
  value,
  verifyAccount,
}: {
  value: string;
  verifyAccount: Boolean;
}) => {
  return axios.post(`/${AUTH_API_PREFIX}/send-sms-verification-code`, { value: value.split(" ").join(""), verifyAccount });
};

/**
 * Login API call
 * @param {value, code} login API request payload
 * @returns Promise
 */
export const login = async ({
  value,
  code,
}: {
  value: string;

  code: string;
}) => {
  return axios.post(`/${AUTH_API_PREFIX}/login`, { value, code });
};

/**
 * Login API call
 * @param {value, code} login API request payload
 * @returns Promise
 */
export const loginWithEmail = async ({ value, password }: { value: string; password: string }) => {
  return axios.post(`/${AUTH_API_PREFIX}/login`, { value, password });
};

/**
 * Register new user API call
 * @param {IRegisterPayload} register API request payload
 * @returns Promise
 */
export const register = async (signupPayload: IRegisterPayload) => {
  return axios.post(`/${AUTH_API_PREFIX}/register`, { ...signupPayload });
};

/**
 * Verify code API call
 * @param {value, code} Verify API request payload
 * @returns Promise
 */
export const verifySmsCode = async ({
  phoneNumber,
  code,
}: {
  phoneNumber: string;
  code: string;
}) => {
  return axios.post(`/${AUTH_API_PREFIX}/verify-sms-code`, { phoneNumber, code });
};

/**
 * Verify code API call
 * @param {value, password} ForgetPassword API request payload
 * @returns Promise
 */
export const forgetPassword = async ({ token, password }: { token: string; password: string }) => {
  return axios.post(`/${AUTH_API_PREFIX}/forgot-password`, { token, password });
};

export const resetPassword = async (payload: ResetPasswordRequestPayload) => {
  return axios.post(`/${AUTH_API_PREFIX}/reset-password`, payload);
};
