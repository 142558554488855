import { Box, Stack, Typography } from '@mui/joy';
import { Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Colors, FlexAlign, FontSizes } from '../../theme';
import ListAllGigs from './ListAllGigs';
import useAuth from '../../hooks/useAuth';
import { UserRole } from '../../types/interfaces';
import CreateGig from './CreateGig';

// eslint-disable-next-line no-unused-vars
enum ActionPath {
  // eslint-disable-next-line no-unused-vars
  ListAllGigs = 'list-all-gigs',
  // eslint-disable-next-line no-unused-vars
  ListMyGigs = 'list-my-gigs',
  // eslint-disable-next-line no-unused-vars
  CreateNewGig = 'create-new-gig',
}

type Action = {
  name: string;
  path: ActionPath;
  roles: UserRole[];
};

const Actions: Action[] = [
  {
    name: 'List all gigs',
    path: ActionPath.ListAllGigs,
    roles: [UserRole.Admin, UserRole.Provider],
  },
  {
    name: 'List my gigs',
    path: ActionPath.ListMyGigs,
    roles: [UserRole.Admin, UserRole.Provider],
  },
  {
    name: 'Create new gig',
    path: ActionPath.CreateNewGig,
    roles: [UserRole.Admin, UserRole.Provider],
  },
];

const SidebarWidth = '320px';

const adminDetailsBoxStyle = {
  marginLeft: '24px',
  marginRight: '24px',
  marginTop: '8px',
  marginBottom: '8px',
};

const AdminPanel = () => {
  const { action: currentAction } = useParams();
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: Colors.White,
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      })}
      direction="row"
    >
      <Stack direction="column">
        <Stack
          direction="column"
          sx={{
            height: '20vh',
            ...FlexAlign.JustifyCenterAlignStart,
            borderBottom: `4px solid ${Colors.AlmostBlack}`,
            borderRight: `4px solid ${Colors.AlmostBlack}`,
            maxWidth: SidebarWidth,
          }}
        >
          <Box sx={adminDetailsBoxStyle}>
            <Typography sx={{ ...FontSizes.BodyLargeW300 }}>
              {`${user.firstName} ${user.lastName}`}
            </Typography>
          </Box>
          <Box sx={adminDetailsBoxStyle}>
            <Typography sx={{ ...FontSizes.BodyRegularW300 }}>{`Role: ${user.role}`}</Typography>
          </Box>
          <Button
            sx={{
              marginLeft: '16px',
              fontWeight: 'bold',
            }}
            color={'error'}
            onClick={logout}
          >
            Logout
          </Button>
        </Stack>

        <Stack
          direction="column"
          sx={{
            width: SidebarWidth,
            height: '80vh',
            overflowY: 'scroll',
            borderRight: `4px solid ${Colors.AlmostBlack}`,
          }}
        >
          {Actions.map((action, index) => {
            if (action.roles.includes(user?.role) === false) return null;

            return (
              <Box
                key={action.name}
                sx={{
                  ...FlexAlign.JustifyStartAlignCenter,
                  height: '48px',
                  paddingLeft: '16px',
                  borderBottom: `1px solid ${Colors.AlmostBlack}`,
                  cursor: 'pointer',
                  backgroundColor: action.path === currentAction ? Colors.GrayDark : Colors.White,
                }}
                onClick={() => navigate(`/admin/${action.path}`)}
              >
                <Typography
                  sx={{
                    ...FontSizes.BodyLargeW300,
                    fontWeight: action.path === currentAction ? 'bold' : undefined,
                  }}
                >
                  {action.name}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Stack>

      <Box sx={{ ...FlexAlign.Center, flex: 1 }}>
        {currentAction === ActionPath.ListMyGigs && <ListAllGigs ownedOnly={true} />}
        {currentAction === ActionPath.ListAllGigs && <ListAllGigs ownedOnly={false} />}
        {currentAction === ActionPath.CreateNewGig && <CreateGig />}
      </Box>
    </Stack>
  );
};

export default AdminPanel;
