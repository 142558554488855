import { extendTheme as extendJoyTheme } from '@mui/joy/styles';

export const Colors = {
  GrayDark: '#E0DEDA',
  GrayLight: '#F5F5F0',
  Black: '#000000',
  LightBlack: 'rgb(40,40,40)',
  AlmostBlack: '#6A6A68',
  Red: '#F07357',
  Green: '#82DA9D',
  Blue: '#173DE5',
  DarkBlue: '#0424b3',
  White: '#FFFFFF',
  Gray800: '#7A8699',
  White50: '#FFFFFF80',
};

export const FlexAlign = {
  Center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  JustifyStartAlignStart: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  JustifyStartAlignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  JustifyStartAlignEnd: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  JustifyCenterAlignStart: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  JustifyCenterAlignEnd: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  JustifyEndAlignStart: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  JustifyEndAlignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  JustifyEndAlignEnd: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
};

export const FontSizes = {
  Header1W700: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '40px',
  },
  Header4W700: {
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 700,
  },
  ButtonSmallW700: {
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: '22px',
  },
  Header3W700: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 700,
  },
  Header2W700: {
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 700,
  },
  Header2W500: {
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 500,
  },
  MediumW600: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 600,
  },
  SmallW300: {
    fontSize: '12px',
    lineHeight: '16px',
    weight: 300,
  },
  SmallerW600: {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 600,
  },
  BodyLargeW300: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: 300,
  },
  BoldCaption1W500: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  BodyRegularW300: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '16px',
  },
  BolderCaptionW500: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19.5px',
  },
  BolderCaptionW700: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16.63px',
  },
  BoldestCaptionW700: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '32px',
  },
  smallCaptionW700: {
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '18px',
  },
  normalCaptionW700: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
  },
  NormalCaptionW300: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
  },
};

const frontlineTheme = extendJoyTheme({
  colorSchemes: {
    light: {
      palette: {
        // affects all Joy components that has `color="primary"` prop.
        primary: {
          500: '#173DE5',
        },
      },
    },
  },
  fontFamily: {
    display: 'Inter var, var(--joy-fontFamily-fallback)',
    body: 'Inter, var(--joy-fontFamily-fallback)',
  },
  components: {
    JoyButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '--Icon-fontSize': '1rem',
          '--Button-gap': '0.25rem',
          '--joy-palette-primary-outlinedDisabledColor': 'gray',
          '--joy-palette-primary-outlinedDisabledBorder': 'gray',
          fontWeight: 700,
          borderRadius: 0,

          '& :disabled': {
            cursor: 'disabled',
          },
        }),
      },
    },
    JoyRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          '--joy-palette-primary-outlinedBorder': theme.palette.primary[500],
          '--Radio-size': '1.5rem',
          '--Radio-gap': '1rem',
          '& .JoyRadio-radio': {
            '--variant-borderWidth': '2px',
            borderColor: theme.palette.primary[500],
            position: 'relative',

            '& .Joy-checked': {
              backgroundColor: theme.palette.primary[500],
            },

            '& .JoyRadio-icon': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.common.white,
              zIndex: 2,
            },
          },
          '& .JoyRadio-label': {
            fontSize: 17,
            fontWeight: 300,
            lineHeight: '24px',
          },
        }),
      },
    },
  },
});
export default frontlineTheme;
